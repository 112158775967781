import React from "react";
import { graphql } from "gatsby"

import SEO from "components/Global/SEO/";
import CardRoute from 'components/Routes/CardRoute';

const Routes = ({data}) => {
  
  const pagina = data.pagina;

  return (
    <>
      <SEO title="Routes" description="Routes" />
      <div className="container">
        <div className="row no-gutters">
          <div className="col-md-6">
            <h1 className="text-primary">{pagina.data.titel.text}<span className="text-secondary">.</span></h1>
            <div dangerouslySetInnerHTML={{__html: pagina.data.intro.html}}></div>
          </div>
          <div className="col-md-2"></div>
          {data.routes.nodes.map((item, key) => {
            return (
              <div key={key} className="col-md-4">
                <CardRoute 
                  url={`/route/${item.uid}`}
                  image={item.data.afbeelding}
                  title={item.data.titel.text}
                  subtitle={item.data.subtitel.text}
                  variant={(key % 2 === 0 ? 'dark' : 'light') }
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query {
    pagina: prismicTekstpagina(uid: {eq: "routes"}) {
      data {
        titel {
          text
        }
        intro {
          html
        }
      }
      url
      uid
    }
    routes: allPrismicRoute(sort: {fields: data___subtitel___text, order: ASC}) {
      nodes {
        id
        uid
        data {
          titel {
            text
          }
          subtitel {
            text
          }
          afbeelding {
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 997) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Routes;